// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-licences-js": () => import("./../../../src/pages/app-licences.js" /* webpackChunkName: "component---src-pages-app-licences-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portal-licences-js": () => import("./../../../src/pages/portal-licences.js" /* webpackChunkName: "component---src-pages-portal-licences-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-website-licences-js": () => import("./../../../src/pages/website-licences.js" /* webpackChunkName: "component---src-pages-website-licences-js" */)
}

